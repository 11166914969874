// header container is in default.scss

@import "./variables";

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_container {
        height: $unit * 6;
    }

    &_logo {
        padding-left: $unit / 2;
        flex: 1;
    }

    .material-icons {
        font-size: 64px;
        color: $color-primary-900;
    }
}

.ReviewHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin-left: $unit;
    }
}
