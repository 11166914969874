@import "variables";

.Register_form {
    &_container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        label {
            background-color: #fff;
            /* margin-bottom: var(--spacing-default); */
            padding: var(--spacing-default) 0;
            /* border: 1px solid var(--color-divider); */
            font-size: calc(var(--font-size-default) / 1.5);
            text-transform: uppercase;
            font-family: "Roboto", sans-serif;
            width: 80%;
        }

        input {
            padding: 0;
        }
    }

    &_action {
        &_container {
            margin: calc(2 * var(--spacing-default)) auto 0;
            width: 80%;
            margin-bottom: var(--spacing-default);
            border-radius: 7px;
            overflow: hidden;
        }

        width: 100%;
        outline: 1px solid var(--color-secondary);
        color: var(--color-primary);

        &_other {
            display: flex;
            width: 80%;
            justify-content: space-between;
            align-items: flex-end;
            min-height: var(--spacing-height);
        }
    }
}
